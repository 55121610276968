import React from "react";
import {
  Select,
  Checkbox,
  MenuItem,
  FormControl,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import _ from "lodash";
import clsx from "clsx";
import { H4, DotText } from "../utils/text";
import colors from "../styles/colors";
import { DownVector } from "./SVG";

const MultiSelect = ({
  list,
  selectedValue = [],
  handleChange,
  value,
  name,
  placeholder = "",
  border = false,
  minWidth,
  height,
  width,
  // eslint-disable-next-line no-unused-vars
  isVendor = false,
  ...props
}) => {
  // eslint-disable-next-line no-unused-vars
  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 500,
    },
    selectRoot: {
      "&:focus": {
        backgroundColor: "#FFF",
      },
    },
    hoverMenuItem: {
      "&:hover": {
        backgroundColor: "#C8E6F1",
        borderRadius: 5,
      },
    },
    menuPropsStyle: (props) => ({
      minWidth: props.width,
      position: "absolute",
      maxHeight: 200,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#004C60",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#FAFAFA",
      },
    }),
  }));
  const classes = useStyles({ border });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const menuProps = {
    PaperProps: {
      style: {
        marginTop: 8,
        backgroundColor: "#FFFFFF",
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
    classes: {
      paper: classes.menuPropsStyle,
    },
  };

  const changeValue = (e) => {
    // console.log(e.target.value);
    handleChange(e.target.value);
  };
  // eslint-disable-next-line no-unused-vars
  const selectedRender = (selected) => {
    if (selected.length === 0) {
      return <H4 label={placeholder} style={{ color: colors.greyText }} />;
    }
    return selected
      .map((s) => {
        const i = _.find(list, (li) => li[value] === s);
        if (i) {
          return i[name];
        }
        return "";
      })
      .join(", ");
  };

  const iconStyles = {
    selectIcon: {
      marginTop: 8,
    },
  };
  const CustomExpandVector = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => (
      <DownVector {...rest} className={clsx(className, classes.selectIcon)} />
    )
  );
  const valueToLabel = {};
  if (list) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of list) {
      valueToLabel[item[value]] = item[name];
    }
  }
  // const placeholderLabel = placeholder || "Select one";
  return (
    <FormControl
      data-qaid='multiselect_form_control'
      variant={border ? "outlined" : "filled"}
      style={{ minWidth }}>
      <Select
        data-qaid='multiselect_select'
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        displayEmpty
        style={{
          border: "1px solid #707272",
          backgroundColor: "#FFFFFF",
          fontSize: 14,
          fontFamily: "AvenirLT",
          height,
          width,
          borderRadius: 4,
        }}
        IconComponent={CustomExpandVector}
        classes={{ root: classes.selectRoot }}
        disableUnderline
        value={selectedValue}
        onChange={changeValue}
        renderValue={selectedRender || placeholder}
        // renderValue={(value) => {
        //   const label = valueToLabel[value];
        //   if (!label) {
        //     return (
        //       <Typography
        //         style={{
        //           color: colors.greyText,
        //         }}>
        //         {placeholderLabel}
        //       </Typography>
        //     );
        //   }
        //   return label;
        // }}
        MenuProps={menuProps}
        {...props}>
        {list.map((item, index) => (
          <MenuItem
            data-qaid='multiselect_menuitem'
            key={index}
            value={item[value]}
            classes={{ root: classes.hoverMenuItem }}
            style={{ maxWidth: width }}>
            <Checkbox
              data-qaid='multiselect_checkbox'
              checked={selectedValue.indexOf(item[value]) > -1}
            />
            <DotText data-qaid='multiselect_label_text' label={item[name]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default React.memo(MultiSelect);
