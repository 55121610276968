import React from "react";
import {
  Select,
  MenuItem,
  Typography,
  FormControl,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import $ from "jquery";
import { DotText } from "../utils/text";
import colors from "../styles/colors";
import { DownVector } from "./SVG";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
  },
  selectRoot: {
    height: "30px",
    alignContent: "center",
    position: "relative",
    borderRadius: 5,
    padding: 3,
    backgroundClip: "padding-box",
    border: "1px solid #707272",
    "&.disabled": {
      border: "1px solid #c3c3c3",
    },
    "&.focused": {
      border: "1px solid #707272",
      borderRadius: 5,
      backgroundClip: "padding-box",
      boxShadow: "0 0 0 2px #FFFFFF,0 0 0 4px #004c60",
    },
    "&:focus": {
      outline: "none",
      border: "1px solid #707272",
      borderRadius: 5,
      backgroundClip: "padding-box",
    },
  },
  hoverMenuItem: {
    "&:hover": {
      backgroundColor: "#F3FAFD",
    },
  },
  menuPropsStyle: (props) => ({
    minWidth: props.width,
    position: "absolute",
    maxHeight: 200,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#004C60",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#FAFAFA",
    },
  }),
}));

const CustomExpandMore = withStyles()(({ open, disabled, ...rest }) => (
  <DownVector
    {...rest}
    style={{
      marginTop: 8,
      marginRight: 5,
    }}
    color={disabled ? "#c3c3c3" : "#004c60"}
  />
));

const SingleSelect = ({
  list,
  handleChange,
  value,
  valueKey,
  displayKey,
  placeholder,
  border = false,
  disabled = false,
  minWidth,
  width,
  FormWidth,
}) => {
  const classes = useStyles({ width: 0 });
  const [open, setOpen] = React.useState(false);

  const getBottomPosition = (boxPosition) => {
    // eslint-disable-next-line no-undef
    const windowHeight = window.innerHeight;
    if (windowHeight > boxPosition.bottom + 207.5) {
      return {
        position: boxPosition.bottom + 7.5,
        top: true,
      };
    }
    return {
      position: windowHeight - boxPosition.top + 7.5,
      top: false,
    };
  };

  const valueToLabel = {};
  if (list) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of list) {
      valueToLabel[item[valueKey]] = item[displayKey];
    }
  }
  const placeholderLabel = placeholder || "Select one";

  const onOpen = (e) => {
    setOpen(true);
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      const els = document.getElementsByClassName(classes.menuPropsStyle);
      const cl = $(e.target).closest(`.${classes.selectRoot}`);
      if (els.length > 0 && cl.length > 0) {
        for (let i = 0; i < els.length; i++) {
          const boxPosition = cl[0].getBoundingClientRect();
          const minWidth = boxPosition.width + 2;
          const left = boxPosition.left - 1;
          const l = getBottomPosition(boxPosition);
          els[i].style["min-width"] = `${minWidth}px`;
          els[i].style["max-width"] = `${minWidth}px`;
          if (l.top) {
            els[i].style.top = `${l.position}px`;
          } else {
            els[i].style.top = null;
            els[i].style.bottom = ` ${l.position}px`;
          }

          els[i].style.left = ` ${left}px`;
        }
      }
    }, 50);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <FormControl
      id='innerView'
      data-qaid='singleselect_formcontrol'
      variant={border ? "outlined" : "filled"}
      style={{
        minWidth,
        width: FormWidth,
        maxWidth: minWidth,
        display: "flex",
        marginTop: 3,
      }}>
      <Select
        data-qaid='singleselect_select'
        value={value}
        onChange={handleChange}
        displayEmpty
        renderValue={(value) => {
          const label = valueToLabel[value];
          if (!label) {
            return (
              <Typography
                style={{
                  color: colors.greyText,
                }}>
                {placeholderLabel}
              </Typography>
            );
          }
          return label;
        }}
        disableUnderline
        style={{
          backgroundColor: "#FFFFFF",
          fontSize: 14,
          fontFamily: "AvenirLT",
          width,
          borderRadius: 4,
          alignItems: "center",
        }}
        classes={{
          root: clsx(classes.selectRoot, {
            disabled,
            focused: open && !disabled,
          }),
        }}
        disabled={disabled}
        onOpen={onOpen}
        onClose={onClose}
        // eslint-disable-next-line react/no-unstable-nested-components
        IconComponent={(props) => (
          <CustomExpandMore {...props} open={open} disabled={disabled} />
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
            minWidth: 496,
          },
          MenuListProps: {
            disablePadding: true,
          },
          getContentAnchorEl: null,
          classes: {
            paper: classes.menuPropsStyle,
          },
        }}
        placeholder={placeholder}
        inputProps={{ "aria-label": "Without label" }}>
        {list &&
          list.length > 0 &&
          list.map((item) => (
            <MenuItem
              data-qaid='singleselect_menuitems_label'
              value={item[valueKey]}
              key={`${item[valueKey]}_option`}
              classes={{ root: classes.hoverMenuItem }}
              style={{ height: 56, borderBottom: "1px solid #EEEEEE" }}>
              <DotText label={item[displayKey]} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default React.memo(SingleSelect);
