import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import InputText from "../../../components/InputText";
import {
  createOrderTypes,
  editOrderTypes,
  deleteOrderTypes,
} from "../../../redux/actions";
import MyContext from "../../../Provider/MyContext";
import { AvenirBlackH4 } from "../../../utils/text";
import { validateText } from "../../../utils/util";
import { RightDrawerTitleButtons } from "../../../components";
import { useOrderType } from "../../../custom-hooks";

const OrderTypeCreateEdit = ({
  open,
  createCallback = () => {},
  editCallback = () => {},
  deleteCallback = () => {},
  onClose,
  orderTypes,
  initialValues = null,
}) => {
  const Context = useContext(MyContext);
  const {
    orderTypeStates,
    errors,
    isEditing,
    updateOrderTypeStates,
    validateOrderType,
  } = useOrderType(initialValues);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const dispatch = useDispatch();
  const handleCreateOrderType = () => {
    if (validateOrderType(orderTypes)) {
      dispatch(createOrderTypes(orderTypeStates, createCallback));
    }
  };
  const handleEditOrderType = () => {
    if (validateOrderType(orderTypes)) {
      dispatch(editOrderTypes(orderTypeStates, editCallback));
    }
  };

  const handleChangeName = (val) => {
    updateOrderTypeStates("name", val);
  };
  const handleChangeDescription = (event) => {
    updateOrderTypeStates("description", event.target.value);
  };
  const handleDeleteOrderType = (value) => {
    if (value) {
      dispatch(
        deleteOrderTypes(
          { otype_id_list: [orderTypeStates.otype_id] },
          deleteCallback
        )
      );
    }
    setShowDeleteDialog(false);
  };
  const handleAlertCloseOpen = (value) => {
    setShowDeleteDialog(value);
  };
  return (
    <RightDrawerTitleButtons
      open={open}
      onClose={onClose}
      isEditing={isEditing}
      title={
        isEditing
          ? Context.langData.edit_order_type
          : Context.langData.create_order_type
      }
      deleteMessage={Context.langData.delete_order_type_body}
      deleteTitle={Context.langData.delete_order_type}
      alertOpenClose={showDeleteDialog}
      handleCreate={handleCreateOrderType}
      handleEdit={handleEditOrderType}
      handleDelete={handleDeleteOrderType}
      handleAlertCloseOpen={handleAlertCloseOpen}>
      <Grid
        direction='column'
        style={{ marginLeft: 56, marginRight: 56, width: 500 }}>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 24,
          }}>
          <AvenirBlackH4
            label={Context.langData.order_type_name}
            style={{ marginBottom: 5 }}
          />
          <InputText
            value={orderTypeStates.name}
            error={errors.name}
            inputPlaceholder='eg: Eat in'
            maxLength={64}
            onChange={(val) => handleChangeName(validateText(val.target.value))}
            id='name'
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 24,
          }}>
          <AvenirBlackH4
            label={Context.langData.description}
            style={{ marginBottom: 5 }}
          />
          <InputText
            onChange={(val) => handleChangeDescription(val)}
            value={orderTypeStates.description}
            inputPlaceholder='eg: Eat in,'
            height={150}
            maxLength={256}
            multiline
            inputProps={{ step: 0.01, min: 0.0 }}
            rows={6}
            id='description'
            error={errors.description}
          />
        </Grid>
      </Grid>
    </RightDrawerTitleButtons>
  );
};

export default OrderTypeCreateEdit;
