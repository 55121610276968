import { Button, Grid, IconButton } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Dialog as AlertDialog,
  ColorSelector,
  InputText,
  RightDrawer,
  SingleSelect,
} from "../../../components";
import CircleClose from "../../../components/SVG/CloseCircle";
import MenuBookIcon from "../../../components/SVG/Menu";
import { Delete } from "../../../components/SVG";
import MyContext from "../../../Provider/MyContext";
import styles from "../../../styles/index";
import { AvenirBlackH4, H4, H8 } from "../../../utils/text";
import { validateAlphaNumeric } from "../../../utils/util";
import {
  editCategories,
  deleteCategories,
  taxProfileList,
} from "../../../redux/actions";

const EditCategory = ({
  open,
  category,
  callback,
  onClose,
  deleteCallBack,
}) => {
  const categories = useSelector((state) => state.menu.categories);
  const taxProfilesList = useSelector(
    (state) => state.taxConfigurationReducer.taxProfileList
  );
  const restId = useSelector((state) => state.loginReducer.selectedRestaurant);
  const [selectedTaxProfile, setSelectedTaxProfile] = useState({});
  const [cat_name, setCatName] = useState("");
  const [error, setError] = useState(null);
  const style = styles();
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const catNames = categories.map((cat) => cat.name.toLowerCase());
  const [item, setItem] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [colorSelection, setColorSelection] = useState({
    bg_colour: "#FFFFFF",
    font_colour: "#000000",
  });
  useEffect(() => {
    if (category) {
      setCatName(category.name);
      setItem(category);
      setColorSelection({
        bg_colour: category.bg_colour,
        font_colour: category.font_colour,
      });
      setSelectedTaxProfile(category.taxp_id);
    }
  }, [category]);
  useEffect(() => {
    dispatch(taxProfileList(restId.rest_id));
  }, []);

  const handleSelectTaxProfile = (value) => {
    setSelectedTaxProfile(value);
  };
  const save = () => {
    if (cat_name && cat_name !== "") {
      if (
        catNames.indexOf(cat_name.toLowerCase()) === -1 ||
        cat_name === item.name
      ) {
        dispatch(
          editCategories(
            {
              name: cat_name,
              cat_id: item.cat_id,
              kitchen_id: item.kitchen_id,
              order: item.order,
              taxp_id: selectedTaxProfile,
              ...colorSelection,
            },
            callback
          )
        );
        setError("");
        navigate(null, {
          replace: true,
          state: { ...category, name: cat_name, taxp_id: selectedTaxProfile },
        });
      } else {
        setError(`${cat_name} already exist`);
      }
    } else {
      setError(Context.langData.valid_name_error);
    }
  };

  const onChange = (val) => {
    setCatName(validateAlphaNumeric(val));
    if (catNames.indexOf(val.toLowerCase()) === -1) {
      setError("");
    } else {
      setError(`${validateAlphaNumeric(val)} already exist`);
    }
  };
  const deleteCategory = () => {
    setShowDialog(true);
  };
  const handleClose = (val) => {
    if (val) {
      dispatch(deleteCategories([item.cat_id], deleteCallBack));
    }
    setShowDialog(false);
  };
  const handleColorChange = (val) => {
    setColorSelection(val);
  };
  return (
    <>
      <RightDrawer data-qaid='editCategory_centermodal' open={open} padding={1}>
        <Grid
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
          }}>
          <Grid
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='assignkitchen_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <IconButton
                onClick={onClose}
                style={{ marginRight: 40, marginTop: 20 }}>
                <CircleClose data-qaid='createcategory_closeicon' size={20} />
              </IconButton>
            </Grid>
            <Grid
              data-qaid='assignkitchen_settings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 56,
                marginBottom: 10,
              }}>
              <MenuBookIcon
                data-qaid='assignkitchen_setting_icon'
                style={{ marginRight: 10 }}
                width={22.5}
                height={24}
                color='#707272'
              />
              <H4
                data-qaid='assignkitchen_settings'
                label={Context.langData.menu}
                style={{ color: "#707272" }}
              />
            </Grid>
            <H8
              data-qaid='assignlitchen_title'
              style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
              label={Context.langData.edit_category}
            />
            <Grid
              data-qaid='editCategory_comment_grid'
              style={{
                marginLeft: 56,
                marginRight: 56,
                width: 500,
                marginBottom: 52,
              }}>
              <ColorSelector
                initialColors={colorSelection}
                style={{ marginTop: 20, marginBottom: 20 }}
                onSelector={handleColorChange}
                name={cat_name}
              />
              <Grid
                data-qaid='editCategory_value_grid'
                container
                style={{ display: "flex", flexDirection: "column" }}>
                <AvenirBlackH4
                  data-qaid='editCategory_valuelabel'
                  label={Context.langData.cat_name}
                  style={{ marginBottom: 10 }}
                />
                <InputText
                  value={cat_name}
                  error={error}
                  maxLength={32}
                  onChange={(val) => onChange(val.target.value)}
                  id='cat_name'
                />
              </Grid>
              <Grid
                data-qaid='createCategeory_taxProfilelistGrid'
                style={{ width: "100%", marginTop: 10 }}>
                <AvenirBlackH4
                  data-qaid='createCategeory_taxProfileLabel'
                  label={Context.langData.taxProfile_select_profile}
                  style={{ marginTop: 20, paddingBottom: 10 }}
                />
                <SingleSelect
                  list={taxProfilesList}
                  value={selectedTaxProfile}
                  valueKey='id'
                  displayKey='name'
                  placeholder='Edit Tax Profile'
                  handleChange={(e) => {
                    handleSelectTaxProfile(e.target.value);
                  }}
                  border={false}
                  height={54}
                  FormWidth='100%'
                  borderStyle='1px solid #707272'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
              zIndex: "999",
            }}>
            <Grid
              data-qaid='editCategory_action_grid'
              container
              style={{ backgroundColor: "#FAFAFA" }}>
              <Grid
                data-qaid='editCategory_cancel_btn'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  // justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                }}>
                <Grid container wrap='nowrap' style={{ width: "100%" }}>
                  <Grid container>
                    <Button
                      className={style.buttonClass}
                      data-qaid='categories_creategrid'
                      style={{ backgroundColor: "#FAFAFA", marginRight: 16 }}
                      variant='text'
                      id='cancel'
                      onClick={() => deleteCategory()}>
                      <Delete
                        size={24}
                        color='#8b0000'
                        data-qaid='categories_createpluse'
                        style={{ marginLeft: 45 }}
                      />
                      <AvenirBlackH4
                        data-qaid='categories_createlabel'
                        style={{ color: "#8b0000", marginLeft: 5 }}
                        label={Context.langData.delete}
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Button
                  className={style.buttonClass}
                  data-qaid='editCategory_cancel_button'
                  onClick={onClose}
                  variant='contained'
                  style={{
                    backgroundColor: "#FFF",
                    marginRight: 16,
                    border: "2px solid #051D33",
                    height: 40,
                    width: 103,
                  }}>
                  <AvenirBlackH4
                    data-qaid='editCategory_cancel_text'
                    label={Context.langData.cancel}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='editCategory_save_btn'
                  variant='contained'
                  style={{
                    backgroundColor: "#FFB600",
                    marginRight: 32,
                    height: 40,
                    width: 90,
                  }}
                  onClick={save}>
                  <AvenirBlackH4
                    data-qaid='createcomment_save_text'
                    label={Context.langData.save}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </RightDrawer>
      <AlertDialog
        data-qaid='categories_alertDialog'
        showDialog={showDialog}
        body={Context.langData.delete_category_body}
        heading={Context.langData.delete_category}
        IsError={false}
        handleClose={handleClose}
      />
    </>
  );
};
export default EditCategory;
